import api from "@/lib/calendesk-js-library/api/api";
import log from "@/lib/calendesk-js-library/tools/log";
import {
  buildPath,
  cloneObject,
  employeePermissions,
  globalErrorCodes,
  hasPermission,
  identifyUser,
  updateLocalesWithMoment,
  updateMomentTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";
import { setItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";

const state = {
  hasAnyPastDueInvoice: false,
  globalErrorCode: null,
  configuration: null,
  adminConfiguration: null,
  translations: null,
  tenant: null,
  tenantHealthy: false,
  preloader: true,
  signupAppBarVisibilityStatus: false,
  locale: {
    timezone: "UTC",
    currency: "EUR",
    locale: "en",
    country_iso_code: null,
  },
  showDrawerOnboarding: true,
  versionCheckedAt: null,
};
const getters = {
  getHasAnyPastDueInvoice: (state) => state.hasAnyPastDueInvoice,
  isPreloading: (state) => state.preloader,
  getAdminConfiguration: (state) => state.adminConfiguration,
  getConfiguration: (state) => state.configuration,
  getLocale: (state) => state.locale,
  getTenant: (state) => state.tenant,
  getShowDrawerOnboarding: (state) => state.showDrawerOnboarding,
  getTenantHealthy: (state) => state.tenantHealthy,
  getVersionCheckedAt: (state) => state.versionCheckedAt,
  getGlobalErrorCode: (state) => state.globalErrorCode,
  getSignupAppBarVisibilityStatus: (state) =>
    state.signupAppBarVisibilityStatus,
};
const actions = {
  init({ commit, state }) {
    return new Promise((resolve) => {
      let tenant = null;
      if (
        localStorage.tenant &&
        localStorage.tenant !== "" &&
        typeof localStorage.tenant !== "undefined"
      ) {
        tenant = localStorage.tenant;
      }

      // The tenant should always match if it doesn't force to the state tenant.
      if (tenant && state.tenant && tenant !== state.tenant) {
        tenant = state.tenant;
      }

      if (localStorage.isUserLogged === "true") {
        commit("auth/SET_IS_USER_LOGGED", true, { root: true });
        commit("SET_TENANT_HEALTHY", true);
        commit("SET_TENANT", tenant);
        resolve();
      } else {
        commit("SET_TENANT_HEALTHY", false);
        commit("SET_TENANT", null);
        resolve();
      }
    });
  },
  getTenant({ commit }, email) {
    return new Promise((resolve, reject) => {
      api
        .getTenantByEmail({ email })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          log.error("Validate tenant failed", error);
          commit("SET_TENANT_HEALTHY", false);
          commit("SET_TENANT", null);
          localStorage.removeItem("tenant");
          reject(error);
        });
    });
  },
  setTenantData({ commit, dispatch }, tenant) {
    commit("SET_TENANT_HEALTHY", true);
    return dispatch("setTenant", tenant.name);
  },
  setTenant({ commit }, name) {
    commit("SET_TENANT", name);
    localStorage.setItem("tenant", name);
  },
  setHasAnyPastDueInvoice({ commit }, status) {
    commit("SET_HAS_ANY_PAST_DUE_INVOICE", status);
  },
  setGlobalErrorCode({ commit }, code) {
    commit("SET_GLOBAL_ERROR_CODE", code);

    setTimeout(() => {
      EventBus.$emit("GLOBAL_ERROR", code);
    }, 500);
  },
  setVersionCheckedAt({ commit }, date) {
    commit("SET_VERSION_CHECKED_AT", date);
  },
  fetchLocale({ state, commit }) {
    return new Promise((resolve, reject) => {
      if (state.configuration) {
        const data = {
          currency: state.configuration.currency,
          locale: state.configuration.language,
          timezone: state.configuration.time_zone,
          country_iso_code: state.configuration.country_iso_code,
        };

        commit("SET_LOCALE", data);

        resolve(data);
      } else if (state.locale && state.locale.country_iso_code) {
        const data = {
          currency: state.locale.currency,
          locale: state.locale.locale,
          timezone: state.locale.timezone,
          country_iso_code: state.locale.country_iso_code,
        };

        commit("SET_LOCALE", data);

        resolve(data);
      } else {
        api
          .getLocale()
          .then(({ data }) => {
            commit("SET_LOCALE", data);
            resolve(data);
          })
          .catch((error) => reject(error));
      }
    });
  },
  fetchSettings({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .configuration()
        .then(({ data }) => {
          commit("SET_CONFIGURATION", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setupLocale({ dispatch }, localeData) {
    return dispatch("shouldRedirectAndFixUrl", localeData.locale).then(() => {
      if (localeData.locale) {
        dispatch("updateLanguage", localeData.locale);
      }

      if (localeData.timezone) {
        updateMomentTimeZone(localeData.timezone);
      }
    });
  },
  loadAuthUser({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .usersData()
        .then((usersDataResponse) => {
          commit("auth/SET_USER", usersDataResponse.data, { root: true });

          commit("cache/RESTORE_CACHE", null, { root: true });
          commit("calendar/RESTORE_CACHE", null, { root: true });
          resolve(usersDataResponse.data);
        })
        .catch((usersDataError) => {
          commit("auth/SET_IS_USER_LOGGED", false, { root: true });
          reject(usersDataError);
        });
    });
  },
  fetchConfiguration({ commit, dispatch, rootState }) {
    return new Promise((resolve, reject) => {
      api
        .configuration()
        .then((configurationResponse) => {
          const configurationData = configurationResponse.data;
          commit("SET_CONFIGURATION", configurationData);

          const setupLocale = (timezone, locale) => {
            return dispatch("setupLocale", { timezone, locale });
          };

          if (rootState.auth.isUserLogged) {
            dispatch("loadAuthUser")
              .then((userData) => {
                const timezone =
                  userData.time_zone || configurationData.time_zone;
                const locale = userData.locale || configurationData.language;

                setupLocale(timezone, locale)
                  .then(() => dispatch("fetchRequiredAppComponents"))
                  .finally(() => resolve(configurationData));
              })
              .catch((usersDataError) => {
                commit("auth/SET_IS_USER_LOGGED", false, { root: true });
                reject(usersDataError);
              });
          } else {
            const timezone = configurationData.time_zone;
            const locale = configurationData.language;
            setupLocale(timezone, locale)
              .then(() => resolve(configurationData))
              .catch(reject);
          }
        })
        .catch(reject);
    });
  },
  fetchRequiredAppComponents({ dispatch, rootGetters, commit }) {
    const loggedUser = rootGetters["auth/getUser"];

    return new Promise((resolve) => {
      const requests = [
        dispatch("fetchAdminConfiguration"),
        dispatch("dashboard/fetchStats", null, { root: true }),
        dispatch("packages/fetchPlans", null, { root: true }),
        dispatch("tag/fetchAll", null, { root: true }),
        dispatch("category/fetchWithServices", null, { root: true }),
        dispatch("packages/fetchCurrentTenantPlan", null, { root: true }),
        dispatch("notification/fetchUsage", null, { root: true }),
        dispatch("availabilitySchedule/fetchAll", null, { root: true }),
      ];

      if (hasPermission(employeePermissions.SHOW_EMPLOYEES)) {
        requests.push(dispatch("employee/fetchAll", null, { root: true }));
      } else if (loggedUser.status === "active" && loggedUser.employee) {
        const clonedUser = cloneObject(loggedUser);
        const { employee } = clonedUser;
        delete clonedUser.employee;
        employee.user = clonedUser;

        const employees = [employee];
        commit("employee/SET_ALL_EMPLOYEES", employees, { root: true });
        commit("employee/SET_ACTIVE_EMPLOYEES", employees, { root: true });
        setItem("calendarSelectedEmployees", [employee.id], true, true);
      }

      if (hasPermission(employeePermissions.PAYMENTS)) {
        requests.push(
          dispatch("payments/fetchInvoiceData", null, { root: true }),
        );
        requests.push(
          dispatch("payments/fetchDefaultPaymentMethod", null, { root: true }),
        );
      }

      if (hasPermission(employeePermissions.STRIPE_INTEGRATION)) {
        requests.push(
          dispatch("stripe/fetchAccount", null, { root: true }).catch(() => {}),
        );
      }

      if (hasPermission(employeePermissions.WEBSITE_BUILDER_EDITOR)) {
        requests.push(dispatch("webDraft/fetchAll", null, { root: true }));
      }

      Promise.all(requests)
        .catch(() => {
          dispatch("setGlobalErrorCode", globalErrorCodes.CONFIGURATION_ERROR);
        })
        .finally(() => {
          const loggedUser = rootGetters["auth/getUser"];
          const currentPlan = rootGetters["packages/getCurrentTenantPlan"];

          identifyUser(loggedUser, currentPlan);
          dispatch("initiateExternalPackages");
          resolve();
        });
    });
  },
  initiateExternalPackages({ state, rootGetters }) {
    return new Promise((resolve) => {
      const loggedUser = rootGetters["auth/getUser"];
      if (loggedUser) {
        window.$chatwoot = window.$chatwoot ?? {};

        if (
          state.tenant &&
          loggedUser.id &&
          typeof window.$chatwoot.setUser === "function"
        ) {
          const uniqueId = state.tenant + "_" + loggedUser.id;

          window.$chatwoot.setUser(uniqueId, {
            name: loggedUser.name || state.configuration.company_name,
            email: loggedUser.email,
          });
        }
      }

      resolve();
    });
  },
  shouldRedirectAndFixUrl(context, language) {
    return new Promise((resolve) => {
      const constructPath = `${window.location.protocol}//${window.location.host}`;
      const urlParts = window.location.pathname.split("/");
      urlParts.splice(0, 1);

      const locales = ["pl", "en", "es", "de"];

      if (urlParts[0] !== language) {
        if (locales.includes(urlParts[0])) {
          // Fix locale switching to the one from configuration
          urlParts[0] = language;
          window.location.replace(
            constructPath + buildPath(urlParts) + window.location.search,
          );
        } else {
          // There is no locale or users uses incorrect one
          window.location.replace(
            `${constructPath}/${language}${window.location.search}`,
          );
        }
      } else {
        resolve();
      }
    });
  },
  updateConfiguration({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .updateConfiguration(payload)
        .then(async () => {
          commit("UPDATE_CONFIGURATION", payload);
          commit("UPDATE_ADMIN_CONFIGURATION", payload);
          await dispatch("fetchConfiguration").then((data) => resolve(data));
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateLanguage({ commit, state }, language) {
    if (language !== localStorage.getItem("locale") || !state.translations) {
      let locale = language ? language.toLowerCase() : "en";

      if (
        locale !== "en" &&
        locale !== "pl" &&
        locale !== "es" &&
        locale !== "de"
      ) {
        locale = "en";
      }

      const files = ["1", "2", "3"];
      const importPromises = files.map((range) => {
        return import(`@/lib/calendesk-js-library/lang/${locale}_${range}`)
          .then((language) => {
            return language.default;
          })
          .catch((error) => {
            window.alert(
              `An error occurred while loading the page: ${error.message}. The page will now reload.`,
            );
            location.reload();
          });
      });

      Promise.all(importPromises).then((translations) => {
        const combinedTranslations = Object.assign({}, ...translations);
        commit("SET_TRANSLATIONS", combinedTranslations);
      });

      updateLocalesWithMoment(locale);
      localStorage.setItem("locale", locale);
      document.documentElement.lang = language;
    }
  },
  fetchAdminConfiguration({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .adminConfiguration()
        .then(({ data }) => {
          commit("SET_ADMIN_CONFIGURATION", data);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  setSignupAppBarVisibilityStatus({ commit }, appBarVisibilityStatus) {
    commit("SET_SIGNUP_APP_BAR_VISIBILITY_STATUS", appBarVisibilityStatus);
  },
  setPreloaderStatus({ commit }, preloaderStatus) {
    commit("SET_PRELOADER", preloaderStatus);
  },
};
const mutations = {
  RESET_CONFIGURATION(state) {
    state.configuration = null;
  },
  RESET_ADMIN_CONFIGURATION(state) {
    state.adminConfiguration = null;
  },
  SET_SIGNUP_APP_BAR_VISIBILITY_STATUS(state, appBarVisibilityStatus) {
    state.signupAppBarVisibilityStatus = appBarVisibilityStatus;
  },
  SET_CONFIGURATION(state, configuration) {
    state.configuration = configuration;
  },
  SET_TRANSLATIONS(state, translations) {
    state.translations = translations;
  },
  UPDATE_CONFIGURATION(state, configuration) {
    state.configuration = {
      ...state.configuration,
      ...configuration,
    };
  },
  UPDATE_ADMIN_CONFIGURATION(state, configuration) {
    state.adminConfiguration = {
      ...state.adminConfiguration,
      ...configuration,
    };
  },
  SET_TENANT(state, tenant) {
    state.tenant = tenant;
  },
  SET_TENANT_HEALTHY(state, healthy) {
    state.tenantHealthy = healthy;
  },
  SET_PRELOADER(state, preloader) {
    state.preloader = preloader;
  },
  SET_ADMIN_CONFIGURATION(state, data) {
    state.adminConfiguration = data;
  },
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  SET_SHOW_DRAWER_ONBOARDING(state, show) {
    state.showDrawerOnboarding = show;
  },
  SET_VERSION_CHECKED_AT(state, date) {
    state.versionCheckedAt = date;
  },
  SET_GLOBAL_ERROR_CODE(state, code) {
    state.globalErrorCode = code;
  },
  SET_HAS_ANY_PAST_DUE_INVOICE(state, status) {
    state.hasAnyPastDueInvoice = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { config } from "@/lib/calendesk-js-library/prototypes/config";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import money from "@/lib/calendesk-js-library/filters/money";

export default function (value) {
  if (!value) {
    return "";
  }

  const variables = [
    { key: "EMPLOYEE_ID", data: 1 },
    { key: "EMPLOYEE_NAME", data: trans("employee_example_name") },
    { key: "EMPLOYEE_SURNAME", data: trans("employee_example_surname") },
    { key: "EMPLOYEE_FULL_NAME", data: trans("employee_example_fullname") },
    { key: "CUSTOMER_FULL_NAME", data: trans("customer_example_fullname") },
    { key: "EMPLOYEE_EMAIL", data: trans("wb_default_email") },
    { key: "EMPLOYEE_PHONE", data: trans("wb_default_phone") },
    { key: "CUSTOMER_ID", data: 8753 },
    { key: "CUSTOMER_NAME", data: trans("customer_example_name") },
    { key: "CUSTOMER_SURNAME", data: trans("customer_example_surname") },
    { key: "CUSTOMER_EMAIL", data: trans("wb_default_email") },
    { key: "CUSTOMER_PHONE", data: trans("wb_default_phone") },
    { key: "BOOKING_ID", data: "1" },
    { key: "BOOKING_START_DATE", data: "2024-01-21" },
    { key: "BOOKING_START_TIME", data: "12:00" },
    { key: "BOOKING_END_DATE", data: "2024-01-21" },
    {
      key: "BOOKING_RECURRENCE_DESCRIPTION",
      data: trans("booking_recurrence_description"),
    },
    { key: "BOOKING_LOCATION", data: trans("service_location_3") },
    { key: "BOOKING_TIME_ZONE", data: config("time_zone") },
    {
      key: "BOOKING_ONLINE_MEETING_URL",
      data: "https://meeting-online.com/abc-1234-cde",
    },
    {
      key: "BOOKING_GOOGLE_MEET_URL",
      data: "https://meet.google.com/abc-1234-cde",
    },
    {
      key: "BOOKING_ZOOM_JOIN_URL",
      data: "https://zoom.us/j/91717684964?pwd=abcdefyw95BNejhxUC9YRzBoU3Iyd109",
    },
    { key: "BOOKING_CONTROL_URL", data: trans("booking_control_url_example") },

    { key: "BOOKING_CREATED_BY_USER_ID", data: 1 },
    { key: "BOOKING_UPDATED_BY_USER_ID", data: 1 },
    { key: "BOOKING_CREATED_BY", data: trans("employee_example_fullname") },
    { key: "BOOKING_UPDATED_BY", data: trans("employee_example_fullname") },

    { key: "BOOKING_CUSTOM_FIELDS", data: trans("custom_fields_example") },
    { key: "BOOKING_END_TIME", data: "11:00" },
    { key: "BOOKING_AWAITING_PAYMENT_TIME", data: 30 },
    { key: "BOOKING_AWAITING_PAYMENT_FULL_TIME", data: "30 min" },
    { key: "BOOKING_PAYMENT_STATUS", data: trans("paid") },
    { key: "SERVICE_ID", data: 15 },
    { key: "SERVICE_NAME", data: trans("service_example_name") },
    { key: "BOOKING_PRICE", data: trans("default_price") },
    { key: "COMPANY_NAME", data: config("company_name") },
    {
      key: "COMPANY_PHONE",
      data: config("company_phone") ?? trans("phone_number_is_missing"),
    },
    {
      key: "COMPANY_ADDRESS",
      data: config("company_address") ?? trans("company_address_is_missing"),
    },
    { key: "BOOKING_STATUS", data: trans("approved") },
    { key: "NEWSLETTER_EMAIL", data: trans("wb_default_email") },
    { key: "PRODUCT_ID", data: 1 },
    {
      key: "PRODUCT_DESCRIPTION",
      data: trans("wb_default_product_description"),
    },
    { key: "PRODUCT_NAME", data: trans("wb_default_product_name") },
    { key: "PRODUCT_PRICE", data: trans("default_price") },
    { key: "PRODUCT_QUANTITY", data: "1" },
    {
      key: "PRODUCT_LIST",
      data: `
<div style="margin: 20px 0 10px 0;"><strong>${trans(
        "additional_products"
      )}:</strong></div>
<table style="text-align:left; border-collapse: collapse; width: 100%; margin-bottom: 10px;">
      <thead>
        <tr>
          <th style="border-bottom: 1px solid #ddd; padding: 10px 0;">${trans(
            "simple_store_product"
          )}</th>
          <th style="border-bottom: 1px solid #ddd; padding: 10px 0;">${trans(
            "price"
          )}</th>
          <th style="border-bottom: 1px solid #ddd; padding: 10px 0;">${trans(
            "additional_information"
          )}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="border-bottom: 1px solid #ddd; padding: 10px 0;">${trans(
            "simple_product_name_example"
          )}</td>
          <td style="border-bottom: 1px solid #ddd; padding: 10px 0;">100,00 ${config(
            "currency"
          )}</td>
          <td style="border-bottom: 1px solid #ddd; padding: 10px 0;">${trans(
            "simple_product_description_example"
          )}</td>
        </tr>
        <tr>
          <td style="border-bottom: 1px solid #ddd; padding: 10px 0;">${trans(
            "simple_product_name_example"
          )}</td>
          <td style="border-bottom: 1px solid #ddd; padding: 10px 0;">200,00 ${config(
            "currency"
          )}</td>
          <td style="border-bottom: 1px solid #ddd; padding: 10px 0;">${trans(
            "simple_product_description_example"
          )}</td>
        </tr>
      </tbody>
    </table>`,
    },
    {
      key: "ADD_TO_CALENDAR_BUTTONS",
      data: `<div>
                <table style="margin: 0 auto;">
             <tr>
                <td valign="top">
                   <table cellpadding="0" border="0" align="center" cellspacing="0" class="rnb-btn-col-content" style="margin:auto; border-collapse: separate;">
                      <tbody>
                         <tr>
                            <td width="auto" valign="middle" align="center" height="30" style="font-size:12px; font-family:Arial,Helvetica,sans-serif; color:#ffffff; font-weight:normal; padding-left:10px; padding-right:10px; vertical-align: middle;border-radius:4px; border: 1px solid #3c4858;">
              <a style="text-decoration:none; color:#3c4858; font-weight:normal;" href="#">${trans(
                "add_to_google_calendar_button"
              )}</a>
                            </td>
                            <td width="20" style="font-size:1px; line-height:20px; mso-hide: all;">&nbsp;</td>
                            <td width="auto" valign="middle"  align="center" height="40" style="font-size:12px; font-family:Arial,Helvetica,sans-serif; color:#ffffff; font-weight:normal; padding-left:10px; padding-right:10px; vertical-align: middle;border-radius:4px; border: 1px solid #3c4858;">
                               <a style="text-decoration:none; color:#3c4858; font-weight:normal;" href="#">${trans(
                                 "add_to_google_ical_outlook_button"
                               )}</a>
                            </td>
                         </tr>
                      </tbody>
                   </table>
                </td>
             </tr>
        </table>
            </div>`,
    },
    {
      key: "MANAGE_CUSTOMER_BOOKING_BUTTON",
      data: `<div>
                <table style="margin: 0 auto;">
                  <tr>
                    <td valign="top">
                       <table cellpadding="0" border="0" align="center" cellspacing="0" class="rnb-btn-col-content" style="margin:auto; border-collapse: separate;">
                          <tbody>
                             <tr>
                                <td width="auto" valign="middle" bgcolor="#0092ff" align="center" height="40" style="font-size:18px; font-family:Arial,Helvetica,sans-serif; color:#ffffff; font-weight:normal; padding-left:20px; padding-right:20px; vertical-align: middle; background-color:#0092ff;border-radius:4px;border-top:0px None #000;border-right:0px None #000;border-bottom:0px None #000;border-left:0px None #000;">
                                   <span style="color:#ffffff; font-weight:normal;">
                                   <a style="text-decoration:none; color:#ffffff; font-weight:normal;" href="#">${trans(
                                     "manage_booking_button_title"
                                   )}</a>
                                   </span>
                                </td>
                             </tr>
                          </tbody>
                       </table>
                    </td>
                  </tr>
            </table>
        </div>`,
    },
    {
      key: "MANAGE_EMPLOYEE_BOOKING_BUTTON",
      data: `<div>
                <table style="margin: 0 auto;">
                  <tr>
                    <td valign="top">
                       <table cellpadding="0" border="0" align="center" cellspacing="0" class="rnb-btn-col-content" style="margin:auto; border-collapse: separate;">
                          <tbody>
                             <tr>
                                <td width="auto" valign="middle" bgcolor="#0092ff" align="center" height="40" style="font-size:18px; font-family:Arial,Helvetica,sans-serif; color:#ffffff; font-weight:normal; padding-left:20px; padding-right:20px; vertical-align: middle; background-color:#0092ff;border-radius:4px;border-top:0px None #000;border-right:0px None #000;border-bottom:0px None #000;border-left:0px None #000;">
                                   <span style="color:#ffffff; font-weight:normal;">
                                   <a style="text-decoration:none; color:#ffffff; font-weight:normal;" href="#">${trans(
                                     "manage_bookings_button_title"
                                   )}</a>
                                   </span>
                                </td>
                             </tr>
                          </tbody>
                       </table>
                    </td>
                  </tr>
            </table>
        </div>`,
    },
  ];

  variables.forEach((object) => {
    value = value.replaceAll(`[${object.key.toUpperCase()}]`, object.data);
  });

  return value;
}

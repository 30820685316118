import vuetify from "@/plugins/vuetify";
import moment from "@/lib/calendesk-js-library/plugins/moment";
import duration from "@/lib/calendesk-js-library/filters/duration";
import { resolveUsername } from "@/lib/calendesk-js-library/resolvers/AuthResolver";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import log from "@/lib/calendesk-js-library/tools/log";
import {
  reportError,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import store from "@/store";
import mixpanel from "mixpanel-browser";
import { getItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import money from "@/lib/calendesk-js-library/filters/money";
import router from "@/router";
import plural from "@/lib/calendesk-js-library/filters/plural";

const helpers = {
  bookingReminderNotificationTypes: {
    EMAIL: 1,
    SMS: 2,
    PUSH: 3,
  },
  bookingReminderRecipientTypes: {
    USER: 1,
    EMPLOYEE: 2,
  },
  bookingDynamicFormTypes: {
    TEXT_FIELD: 0,
    TEXT_AREA: 1,
    CHECKBOX: 2,
    RADIO: 3,
    EMAIL: 4,
    PHONE: 5,
  },
  userPaymentTypeMode: {
    BOOKINGS: 1,
    USER_SUBSCRIPTIONS: 2,
    SIMPLE_STORE_PRODUCTS: 3,
  },
  scheduleRuleType: {
    WDAY: "wday",
    DATE: "date",
  },
  dynamicFormType: {
    GENERAL: "general",
    BOOKING: "booking",
  },
  userSubscriptionStatus: {
    INITIATED: "initiated",
    CANCELED: "canceled",
    ACTIVE: "active",
  },
  serviceStatus: {
    ACTIVE: "active",
    INACTIVE: "inactive",
  },
  userStatus: {
    ACTIVE: "active",
    INACTIVE: "inactive",
  },
  languages: {
    POLISH: "pl",
    ENGLISH: "en",
  },
  dateFormat: "YYYY-MM-DD",
  timeFormat: "HH:mm",
  dateTimeFormat: "YYYY-MM-DD HH:mm",
  DMYT: "DD.MM.YYYY HH:mm",
  DMY: "DD.MM.YYYY",
  DM: "DD.MM",
  dayAndMonthDate: "DD MMMM",
  dayMonthAndYearDate: "DD MMMM YYYY",
  hourDayMonthAndYearDate: "DD MMMM YYYY, HH:mm",
  secondHourDayMonthAndYearDate: "DD MMMM YYYY, HH:mm:ss",
  dayAndMonthVerbally: "Do MMM",
  dayAndMonthVerballyFull: "D MMMM YYYY",
  fullWithMonthTextAndDate: "LL",
  isoFormat: "YYYY-MM-DDTHH:mm",
  maxNumbersInPlan: {
    lite: {
      employees: 1,
      webDrafts: 1,
    },
    standard: {
      employees: 5,
      webDrafts: 2,
    },
  },
  eventTypes: {
    booking: "booking",
    unavailableBookingSlot: "unavailable_booking_slot",
    groupBooking: "group_booking",
  },
  draftType: {
    WEB: 1,
    MOBILE: 2,
  },
  weekdaysSorter: {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  },
  weekdaysSorter2: {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  },
  allWeekdays: [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ],
  plans: {
    START: "start",
    BUSINESS: "business",
    PRO: "pro",
    CD_BASE_LITE: "cd_base_lite",
    CD_BASE_STANDARD: "cd_base_standard",
    CD_BASE_PRO: "cd_base_pro",
    CD_BASE_ENTERPRISE: "cd_base_enterprise",
  },
  serviceLocationTypes: {
    IN_PERSON: 1,
    PHONE: 2,
    GOOGLE_MEET: 3,
    OTHER: 4,
    ZOOM: 5,
    TEAMS: 6,
    SKYPE: 7,
    WHATSAPP: 8,
  },
  staticRuleLocationTypes: {
    ONLINE: "online",
    PHONE: "phone",
  },
  planPackagesTypes: {
    PLAN: "plan",
    EXTRA: "extra",
    CONFIG: "config",
  },
  periodTypes: {
    MONTH: "month",
    YEAR: "year",
  },
  paymentMethods: {
    STRIPE: "stripe",
    CASH: "cash",
    TRANSFER: "transfer",
    CARD: "card",
    OTHER: "other",
    SUBSCRIPTION: "subscription",
  },
  availableGtuCodes: [
    "GTU_01",
    "GTU_02",
    "GTU_03",
    "GTU_04",
    "GTU_05",
    "GTU_06",
    "GTU_07",
    "GTU_08",
    "GTU_09",
    "GTU_10",
    "GTU_11",
    "GTU_12",
    "GTU_13",
  ],
  permissions: {
    root: 1, // This can everything
    admin: 2, // Allow login to admin's panel.
    permissions: 3, // Access to permissions
    client_app: 15,
  },
  roles: {
    employee: 4,
  },
  roleSlugs: {
    root: "root",
    admin: "admin",
    employee: "employee",
    employee_basic: "employee_basic",
  },
  customerNotificationCustomerType: {
    user: 1,
    newsletter: 2,
    employee: 3,
  },
  customerNotificationType: {
    email: 1,
    sms: 2,
    push: 3,
  },
  statsMode: {
    users: 1,
    services: 2,
    categories: 3,
    newsletter: 4,
    employees: 5,
    bookings: 6,
    webDrafts: 7,
    simpleStoreProductList: 8,
    simpleStoreProductTransactionList: 9,
    subscriptions: 10,
    userSubscriptions: 11,
    mobileDrafts: 12,
  },
  colorsInClientApp: 20,
  cleanObj(obj) {
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === "" ||
        obj[propName] === "null"
      ) {
        delete obj[propName];
      }
    }
  },
  copyObj(obj) {
    return { ...obj };
  },
  emailIsTakenError(error) {
    return (
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.email
    );
  },
  getTemplateWithAboutMeView(templateId) {
    const templateWithAboutMeView = [5, 15];
    return templateWithAboutMeView.find((_) => _ === templateId) || false;
  },
  openUrl(url) {
    window.open(url, "_blank");
  },
  hexToRgba,
  cloneObject,
  getUserName,
  getEmployeeName,
  goToEmployee,
  goToService,
  goToUser,
  getAvatarSrc,
  getServiceName,
  getStatusColor,
  addMinutes,
  replaceFromArray,
  userHasRole,
  parseJson,
  pushEvent,
  addressIsEmpty,
  getServiceAndItsTypeForId,
  generateRandomString,
  copyToClipboard(str) {
    const el = document.createElement("textarea");
    el.addEventListener("focusin", (e) => e.stopPropagation());
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  },
  copyAndNotify(string) {
    this.copyToClipboard(string);
    successNotification("copied_to_clipboard");
  },
  isResponseErrorCode(error, code) {
    return (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.code &&
      error.response.data.code === code
    );
  },
  getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
  },
  userIsRoot(user) {
    return userHasRole(user, helpers.roleSlugs.root);
  },
};

export function getStatusColor(status) {
  const colors = {
    approved: {
      color: "green-light",
      class: "green--text",
    },
    cancelled: {
      color: "red-light",
      class: "red--text",
    },
    done: {
      color: "blue-light",
      class: "blue--text",
    },
    active: {
      color: "green-light",
      class: "green--text",
    },
    inactive: {
      color: "red-light",
      class: "red--text",
    },
  };

  // eslint-disable-next-line no-prototype-builtins
  if (!colors.hasOwnProperty(status)) {
    return "";
  }

  return colors[status];
}

export function customPhoneFilter(item, queryText, itemText) {
  const textOne = item.name.toLowerCase();
  const textTwo = item.iso2;
  const textThree = item.showDialCode;
  const searchText = queryText.toLowerCase();

  return (
    textOne.indexOf(searchText) > -1 ||
    textTwo.indexOf(searchText) > -1 ||
    textThree.replace("+", "") === searchText.replace("+", "")
  );
}

export function getTaxValues() {
  const values = [];
  for (let i = 0; i <= 100; i++) {
    values.push(String(i));
  }

  values.push("np");
  values.push("zw");

  return values;
}

export function platformTimeZone() {
  return store.getters["setup/getConfiguration"].time_zone;
}

export function userTimeZone() {
  const userTimeZone = store.getters["auth/getUser"]?.time_zone;
  const configurationTimezone = platformTimeZone();

  return userTimeZone || configurationTimezone;
}

export function getEmployeeName(employee, hideId = false) {
  return getUserName(employee.user, employee, hideId);
}

export function getUserName(user, employee = null, hideId = false) {
  if (user && typeof user !== "undefined") {
    const id = hideId
      ? ""
      : employee
        ? `(${trans("id")}: ${employee.id}) `
        : `(${trans("id")}: ${user.id}) `;
    return `${id}${resolveUsername(user)}`;
  }

  return "";
}

export function getAvatarSrc(item) {
  if (item && item.default_image) {
    const configuration = store.getters["setup/getConfiguration"];

    if (configuration) {
      return `${configuration.image_base_url}${item.default_image.name}`;
    }
  }

  return require("@/lib/calendesk-js-library/assets/placeholder.jpg");
}

export function getMediaSrc(item) {
  if (item && item.name) {
    const configuration = store.getters["setup/getConfiguration"];

    return `${configuration.image_base_url}${item.name}`;
  }

  return require("@/lib/calendesk-js-library/assets/placeholder.jpg");
}

export function goToUser(user, push = false) {
  if (hasPermission(employeePermissions.SHOW_USERS) && user) {
    if (push) {
      router.push({ name: "user", params: { id: user.id } });
    } else {
      const configuration = store.getters["setup/getConfiguration"];

      const url =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        "/" +
        (configuration.language || "en") +
        "/panel/customers/" +
        user.id;

      window.open(url, "_blank");
    }
  }
}

export function getCurrentTabsLink(route, defaultRoute) {
  const getCTabsState = store.getters["cache/getCTabsState"];
  if (getCTabsState && getCTabsState[route]) {
    return { name: route, params: { tab: getCTabsState[route] } };
  }

  return defaultRoute;
}

export function goToEmployee(employee, push = false) {
  if (hasPermission(employeePermissions.SHOW_EMPLOYEES)) {
    if (push) {
      router.push({ name: "employee", params: { id: employee.id } });
    } else {
      const configuration = store.getters["setup/getConfiguration"];

      const url =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        "/" +
        (configuration.language || "en") +
        "/panel/employees/" +
        employee.id;

      window.open(url, "_blank");
    }
  }
}

export function goToService(service, push = false) {
  if (hasPermission(employeePermissions.EDIT_SERVICES)) {
    if (push) {
      router.push({ name: "service", params: { id: service.id } });
    } else {
      const configuration = store.getters["setup/getConfiguration"];

      const url =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "") +
        "/" +
        (configuration.language || "en") +
        "/panel/offer/services/" +
        service.id;

      window.open(url, "_blank");
    }
  }
}

export function serviceDurationList() {
  const generate0 = durationList(1, 60, 1);
  const generate1 = durationList(60, 1435, 5);
  return generate0.concat(generate1);
}

export function durationList(start, end, step, returnNumbers = false) {
  if (start === undefined || start === null) {
    start = 5;
  }

  end = end || 1435;
  step = step || 5;

  function* range(start, end, step) {
    while (start <= end) {
      yield start;
      start += step;
    }
  }

  const out = Array.from(range(start, end, step));
  return out.map((x) => (returnNumbers ? x : { duration: x }));
}

export function addMinutes(time, minutesToAdd) {
  function D(J) {
    return (J < 10 ? "0" : "") + J;
  }

  const piece = time.split(":");
  const minutes = piece[0] * 60 + +piece[1] + +minutesToAdd;

  return `${D(((minutes % (24 * 60)) / 60) | 0)}:${D(minutes % 60)}`;
}

export function generateTimes(hStart, hEnd, step) {
  const x = {
    slotInterval: step,
    openTime: hStart,
    closeTime: hEnd,
  };

  let startTime = moment(x.openTime, "HH:mm");
  const endTime = moment(x.closeTime, "HH:mm");

  // Round startTime up to the next quarter hour if it isn't already
  if (startTime.minute() % x.slotInterval !== 0) {
    startTime = startTime.add(
      x.slotInterval - (startTime.minute() % x.slotInterval),
      "minutes",
    );
  }

  const allTimes = [];

  // eslint-disable-next-line no-unmodified-loop-condition
  while (startTime <= endTime) {
    allTimes.push(startTime.format("HH:mm"));
    startTime.add(x.slotInterval, "minutes");
  }
  return allTimes;
}

export function removeEmptyValues(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined || obj[key] === null) {
      delete obj[key];
    }
  });
}

export const parseTime = (a) => {
  if (!a) {
    a = "00:00";
  }

  return String(a)
    .split(":")
    .map((item) => parseInt(item))
    .reduce((a, b) => a * 60 + b);
};

export function updateLocales(locale) {
  vuetify.framework.lang.current = locale;
}

export function updateLocalesWithMoment(locale) {
  vuetify.framework.lang.current = locale;
  moment.locale(locale);
}

export function updateMomentTimeZone(timeZone) {
  moment.tz.setDefault(timeZone);
}

export function hexToRgba(hex, alpha = 1, returnArray = false) {
  let color;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    color = hex.substring(1).split("");
    if (color.length === 3) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]];
    }
    color = `0x${color.join("")}`;

    if (returnArray) {
      return [(color >> 16) & 255, (color >> 8) & 255, color & 255];
    }
    return `rgba(${[(color >> 16) & 255, (color >> 8) & 255, color & 255].join(
      ", ",
    )}, ${alpha})`;
  }
  throw new Error("Bad Hex");
}

export function rgbToHex(rgb) {
  return `#${rgb
    .match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    .slice(1)
    .map((n) => parseInt(n, 10).toString(16).padStart(2, "0"))
    .join("")}`;
}

export function isRgbColor(string) {
  return string.match(
    /^rgb[(](?:\s*0*(?:\d\d?(?:\.\d+)?(?:\s*%)?|\.\d+\s*%|100(?:\.0*)?\s*%|(?:1\d\d|2[0-4]\d|25[0-5])(?:\.\d+)?)\s*(?:,(?![)])|(?=[)]))){3}[)]$/,
  );
}

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function replaceFromArray(string, find, replace) {
  for (let i = 0; i < find.length; i++) {
    string = string.replace(find[i], replace);
  }
  return string;
}

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function parseJson(data) {
  return JSON.parse(data);
}

export function buildPath(theArray) {
  let newPathname = "";
  for (let i = 0; i < theArray.length; i++) {
    newPathname += "/";
    newPathname += theArray[i];
  }
  return newPathname;
}

export function insertTextOnCursorPosition(
  self,
  text,
  refName,
  localVariableName,
) {
  if (text.length > 0 && self.$refs[refName] !== null) {
    const textarea = self.$refs[refName].$refs.input;
    const currentValue = textarea.value;
    const len = currentValue.length;
    let pos = textarea.selectionStart;
    if (pos === undefined) {
      pos = 0;
    }

    const before = currentValue.substr(0, pos);
    const after = currentValue.substr(pos, len);

    self[localVariableName] = before + text + after;

    self.$nextTick().then(() => {
      textarea.selectionStart = pos + text.length;
    });
  }
}

export function insertTextToHTMLTextarea(self, text, refName) {
  if (text.length > 0 && self.$refs[refName] !== null) {
    self.$refs[refName].pasteContent(text);
  }
}

export function isSubdomain(url) {
  if (url) {
    // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
    url = url.replace(new RegExp(/^\s+/), "");
    url = url.replace(new RegExp(/\s+$/), "");

    // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
    url = url.replace(new RegExp(/\\/g), "/");

    // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
    url = url.replace(new RegExp(/^http:\/\/|^https:\/\/|^ftp:\/\//i), "");

    // IF THERE, REMOVES 'www.' FROM THE START OF THE STRING
    url = url.replace(new RegExp(/^www\./i), "");

    // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
    url = url.replace(new RegExp(/\/(.*)/), "");

    // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
    if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
      url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), "");

      // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
    } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
      url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), "");
    }

    // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
    return !!url.match(new RegExp(/\./g));
  }

  return false;
}

export function getServiceName(
  service,
  serviceType = null,
  hideDetails = false,
  hideOnlyDuration = false,
  hideIds = false,
  hideServiceType = false,
) {
  serviceType = !hideServiceType
    ? serviceType || service.selectedType || service.selected_type || null
    : null;
  let serviceDuration = serviceType ? serviceType.duration : service.duration;
  serviceDuration = serviceDuration ? duration(serviceDuration) : null;

  let servicePrice = serviceType ? serviceType.price : service.price;
  servicePrice = servicePrice && servicePrice > 0 ? money(servicePrice) : null;

  if (serviceDuration === null && servicePrice === null) {
    hideDetails = true;
  }

  let details = hideOnlyDuration
    ? servicePrice
      ? ` (${servicePrice})`
      : ""
    : hideDetails
      ? ""
      : ` (${serviceDuration}` + (servicePrice ? `, ${servicePrice})` : ")");

  if (serviceType) {
    if (hideIds) {
      return `${service.name} - ${serviceType.name}${details}`;
    } else {
      return `(${trans("id")}: ${service.id}/${serviceType.id}) ${
        service.name
      } - ${serviceType.name}${details}`;
    }
  }

  if (hideIds) {
    return `${service.name}${details}`;
  } else {
    return `(${trans("id")}: ${service.id}) ${service.name}${details}`;
  }
}

export function getServicePrice(service, serviceType) {
  serviceType =
    serviceType || service.selectedType || service.selected_type || null;
  return serviceType ? serviceType.price : service.price;
}

export function getSearchServiceName(service) {
  const hasTypes = service.types && service.types.length > 0;

  if (hasTypes) {
    return `(${trans("id")}: ${service.id}) ${service.name} (${trans(
      "variants",
    )}: ${service.types.length})`;
  }

  const serviceDuration = duration(service.duration);
  const servicePrice = money(service.price);

  return `(${trans("id")}: ${service.id}) ${
    service.name
  } (${serviceDuration}, ${servicePrice})`;
}

export function getSubscriptionName(subscription) {
  return `(${trans("id")}: ${subscription.id}) ${subscription.name}`;
}

export function calculateDuration(
  startDate,
  endDate,
  startTime = "00:00",
  endTime = "23:59",
) {
  // Combine dates and times into moment objects
  const startDateTime = moment(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm");
  const endDateTime = moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm");

  // Calculate difference in minutes
  const duration = moment.duration(endDateTime.diff(startDateTime));

  // Return duration in minutes
  return duration.asMinutes();
}

export function getTrackingUserId() {
  const tenant = store.getters["setup/getTenant"];
  const userId = store.getters["auth/getUserId"];

  if (tenant && userId) {
    return `${tenant}_${userId}`;
  }

  return null;
}

export function pushEvent(name, payload) {
  const ignoreTracking = getItem("ignore_tracking", false, true);

  if (!ignoreTracking) {
    window.dataLayer = window.dataLayer || [];
    window.uetq = window.uetq || [];

    if (!payload) {
      payload = {};
    }

    payload.event = name;
    payload.user_id = getTrackingUserId();

    try {
      if (process.env.NODE_ENV === "production") {
        mixpanel.track(name, payload);
      }

      window.dataLayer.push(payload);
      window.uetq.push("event", name, payload);
    } catch (error) {
      // Silent
    }
  }
}

export function chargeUser(type, amount, currency) {
  let exchange = 1.0;
  if (currency !== "USD") {
    switch (currency) {
      case "PLN":
        exchange = 0.23;
        break;
      case "EUR":
        exchange = 1.07;
        break;
      case "GBP":
        exchange = 1.25;
    }
  }

  try {
    const localCurrencyValue = amount / 100;
    const exchangedValue = (amount * exchange) / 100;

    const roundedLocalCurrencyValue =
      Math.round(localCurrencyValue * 100) / 100;
    const roundedExchangedValue = Math.round(exchangedValue * 100) / 100;

    if (process.env.NODE_ENV === "production") {
      mixpanel.people.track_charge(roundedExchangedValue, {
        original_currency: currency,
        original_amount: roundedLocalCurrencyValue,
        exchange_rate: exchange,
        type: type,
      });
    }

    window.dataLayer.push({
      user_id: getTrackingUserId(),
      event: "charge_user",
      currency: currency,
      amount: roundedLocalCurrencyValue,
      type: type,
    });
  } catch (error) {
    reportError("CHARGE USER CATCH", {
      type: type,
      amount: amount,
      currency: currency,
      error: error,
    });
  }
}

export function identifyUser(user, currentPlan) {
  const ignoreTracking = getItem("ignore_tracking", false, true);

  if (!ignoreTracking) {
    const tenant = store.getters["setup/getTenant"];

    if (user && currentPlan && tenant) {
      const distinctId = getTrackingUserId();
      const filteredRoles = user.roles
        ? user.roles.map((role) => role.name)
        : [];
      const isRoot = filteredRoles.includes("root");

      try {
        if (process.env.NODE_ENV === "production") {
          mixpanel.identify(distinctId);

          mixpanel.people.set({
            $email: user.email,
            $phone: user.default_phone ? user.default_phone.e164 : null,
            $distinct_id: distinctId,
            $first_name: user.name,
            $last_name: user.surname,
            is_root: isRoot,
            roles: filteredRoles.join(","),
            plan_slug: currentPlan.slug,
            plan_on_trial: currentPlan.on_trial,
          });
        }

        window.dataLayer.push({
          event: "identify_user",
          user_id: distinctId,
          is_root: isRoot,
          plan_slug: currentPlan.slug,
          plan_on_trial: currentPlan.on_trial,
        });
      } catch (error) {
        // Silent
      }
    }
  }
}

export function defaultCalendarWeekDays(skipWeekends = false) {
  const result = [
    {
      day: "monday",
      start_time: "08:00",
      end_time: "17:00",
    },
    {
      day: "tuesday",
      start_time: "08:00",
      end_time: "17:00",
    },
    {
      day: "wednesday",
      start_time: "08:00",
      end_time: "17:00",
    },
    {
      day: "thursday",
      start_time: "08:00",
      end_time: "17:00",
    },
    {
      day: "friday",
      start_time: "08:00",
      end_time: "17:00",
    },
  ];

  if (!skipWeekends) {
    result.push({
      day: "saturday",
      start_time: "08:00",
      end_time: "17:00",
    });

    result.push({
      day: "sunday",
      start_time: "08:00",
      end_time: "17:00",
    });
  }

  return result;
}

export function hasPermission(permission) {
  const loggedUser = store.getters["auth/getUser"];

  if (loggedUser) {
    let permissions = [];
    let permissionInRoles = [];

    if (loggedUser.permissions) {
      permissions = loggedUser.permissions.map((permission) => permission.name);
    }

    const hasPermission = permissions.includes(permission);

    if (loggedUser.roles) {
      permissionInRoles = loggedUser.roles.filter(
        (role) =>
          role.name === "root" ||
          role.name === "admin" ||
          role.permissions.map((p) => p.name).includes(permission),
      );
    }

    return hasPermission || permissionInRoles.length > 0;
  }

  return false;
}

export function userHasRole(user, role) {
  if (user) {
    let roles = [];

    if (user.roles) {
      roles = user.roles.map((role) => role.name);
    }

    return roles.includes(role);
  }

  return false;
}

export function hasOnlyRole(role) {
  const loggedUser = store.getters["auth/getUser"];

  if (loggedUser) {
    let roles = [];

    if (loggedUser.roles) {
      roles = loggedUser.roles.map((role) => role.name);
    }

    return roles.length === 1 && roles[0] === role;
  }

  return false;
}

export const globalErrorCodes = {
  CONFIGURATION_ERROR: "CONFIGURATION_ERROR",
  ACCOUNT_ACTIVATION: "ACCOUNT_ACTIVATION",
  LOCALE_ERROR: "LOCALE_ERROR",
};

export const employeePermissions = {
  PANEL_ACCESS: "panel_access",
  SHOW_PACKAGES: "show_packages",
  EDIT_PACKAGES: "edit_packages",
  PACKAGE_PURCHASE: "package_purchase",
  SHOW_TENANT_PAYMENTS_TRANSACTIONS: "show_tenant_payments_transactions",
  SHOW_LOGS: "show_logs",
  EDITOR: "editor",
  WEBSITE_BUILDER_EDITOR: "website_builder_editor",
  MOBILE_APP_BUILDER_EDITOR: "mobile_app_builder_editor",
  DOMAIN_EDITOR: "domain_editor",
  EDIT_SETTINGS: "edit_settings",
  SHOW_STATS: "show_stats",
  EDIT_TRANSLATIONS: "edit_translations",
  ADD_USERS: "add_users",
  SHOW_USERS: "show_users",
  EDIT_USERS: "edit_users",
  DELETE_USERS: "delete_users",
  SEARCH_USERS: "search_users",
  SEARCH_OWN_USERS: "search_own_users",
  ADD_GROUPS: "add_groups",
  SHOW_GROUPS: "show_groups",
  EDIT_GROUPS: "edit_groups",
  DELETE_GROUPS: "delete_groups",
  ADD_USER_IMAGES: "add_user_images",
  SHOW_USER_IMAGES: "show_user_images",
  EDIT_USER_IMAGES: "edit_user_images",
  DELETE_USER_IMAGES: "delete_user_images",
  ADD_EMPLOYEES: "add_employees",
  SHOW_EMPLOYEES: "show_employees",
  EDIT_EMPLOYEES: "edit_employees",
  DELETE_EMPLOYEES: "delete_employees",
  ADD_SERVICES: "add_services",
  SHOW_SERVICES: "show_services",
  EDIT_SERVICES: "edit_services",
  DELETE_SERVICES: "delete_services",
  ADD_CATEGORIES: "add_categories",
  SHOW_CATEGORIES: "show_categories",
  EDIT_CATEGORIES: "edit_categories",
  DELETE_CATEGORIES: "delete_categories",
  ADD_RESOURCES: "add_resources",
  SHOW_RESOURCES: "show_resources",
  EDIT_RESOURCES: "edit_resources",
  DELETE_RESOURCES: "delete_resources",
  ADD_NEWSLETTER: "add_newsletter",
  SHOW_NEWSLETTER: "show_newsletter",
  EDIT_NEWSLETTER: "edit_newsletter",
  DELETE_NEWSLETTER: "delete_newsletter",
  ADD_REVIEWS: "add_reviews",
  SHOW_REVIEWS: "show_reviews",
  EDIT_REVIEWS: "edit_reviews",
  DELETE_REVIEWS: "delete_reviews",
  ADD_PERMISSIONS: "add_permissions",
  SHOW_PERMISSIONS: "show_permissions",
  DELETE_PERMISSIONS: "delete_permissions",
  ADD_BOOKINGS: "add_bookings",
  SHOW_BOOKINGS: "show_bookings",
  EDIT_BOOKINGS: "edit_bookings",
  DELETE_BOOKINGS: "delete_bookings",
  ADD_BOOKING_PAYMENTS: "add_booking_payments",
  DELETE_BOOKING_PAYMENTS: "delete_booking_payments",
  SHOW_BOOKING_PAYMENTS: "show_booking_payments",
  EDIT_BOOKING_PAYMENTS: "edit_booking_payments",
  ADD_OWN_BOOKING_PAYMENTS: "add_own_booking_payments",
  DELETE_OWN_BOOKING_PAYMENTS: "delete_own_booking_payments",
  SHOW_OWN_BOOKING_PAYMENTS: "show_own_booking_payments",
  EDIT_OWN_BOOKING_PAYMENTS: "edit_own_booking_payments",
  ADD_OWN_BOOKINGS: "add_own_bookings",
  SHOW_OWN_BOOKINGS: "show_own_bookings",
  EDIT_OWN_BOOKINGS: "edit_own_bookings",
  DELETE_OWN_BOOKINGS: "delete_own_bookings",
  SHOW_USER_SUBSCRIPTION_TRANSACTIONS: "show_user_subscription_transactions",
  EDIT_USER_SUBSCRIPTION_TRANSACTIONS: "edit_user_subscription_transactions",
  ADD_USER_SUBSCRIPTION_TRANSACTIONS: "add_user_subscription_transactions",
  DELETE_USER_SUBSCRIPTION_TRANSACTIONS:
    "delete_user_subscription_transactions",
  SHOW_USER_SUBSCRIPTIONS: "show_user_subscriptions",
  EDIT_USER_SUBSCRIPTIONS: "edit_user_subscriptions",
  ADD_USER_SUBSCRIPTIONS: "add_user_subscriptions",
  DELETE_USER_SUBSCRIPTIONS: "delete_user_subscriptions",
  SHOW_SUBSCRIPTIONS: "show_subscriptions",
  SEARCH_SUBSCRIPTIONS: "search_subscriptions",
  EDIT_SUBSCRIPTIONS: "edit_subscriptions",
  ADD_SUBSCRIPTIONS: "add_subscriptions",
  DELETE_SUBSCRIPTIONS: "delete_subscriptions",
  SHOW_STORE_PRODUCTS: "show_store_products",
  EDIT_STORE_PRODUCTS: "edit_store_products",
  DELETE_STORE_PRODUCTS: "delete_store_products",
  ADD_STORE_PRODUCTS: "add_store_products",
  SHOW_STORE_PRODUCT_TRANSACTIONS: "show_store_product_transactions",
  CREATE_STORE_PRODUCT_TRANSACTIONS: "create_store_product_transactions",
  DELETE_STORE_PRODUCT_TRANSACTIONS: "delete_store_product_transactions",
  ADD_UNAVAILABLE_BOOKING_SLOTS: "add_unavailable_booking_slots",
  SHOW_UNAVAILABLE_BOOKING_SLOTS: "show_unavailable_booking_slots",
  EDIT_UNAVAILABLE_BOOKING_SLOTS: "edit_unavailable_booking_slots",
  DELETE_UNAVAILABLE_BOOKING_SLOTS: "delete_unavailable_booking_slots",
  ADD_OWN_UNAVAILABLE_BOOKING_SLOTS: "add_own_navailable_booking_slots",
  SHOW_OWN_UNAVAILABLE_BOOKING_SLOTS: "show_own_unavailable_booking_slots",
  EDIT_OWN_UNAVAILABLE_BOOKING_SLOTS: "edit_own_unavailable_booking_slots",
  DELETE_OWN_UNAVAILABLE_BOOKING_SLOTS: "delete_own_unavailable_booking_slots",
  ADD_TAGS: "add_tags",
  SHOW_TAGS: "show_tags",
  EDIT_TAGS: "edit_tags",
  DELETE_TAGS: "delete_tags",
  ADD_NOTIFICATIONS: "add_notifications",
  SHOW_NOTIFICATIONS: "show_notifications",
  SHOW_INVOICES: "show_invoices",
  PAYMENTS: "payments",
  STRIPE_INTEGRATION: "stripe_integration",
  ADD_AVAILABILITY_SCHEDULES: "add_availability_schedules",
  SHOW_AVAILABILITY_SCHEDULES: "show_availability_schedules",
  EDIT_AVAILABILITY_SCHEDULES: "edit_availability_schedules",
  DELETE_AVAILABILITY_SCHEDULES: "delete_availability_schedules",
  ADD_OWN_AVAILABILITY_SCHEDULES: "add_own_availability_schedules",
  SHOW_OWN_AVAILABILITY_SCHEDULES: "show_own_availability_schedules",
  EDIT_OWN_AVAILABILITY_SCHEDULES: "edit_own_availability_schedules",
  DELETE_OWN_AVAILABILITY_SCHEDULES: "delete_own_availability_schedules",
};

export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export function getAvailableLocationFilters() {
  return [
    {
      text: trans("service_location_1"),
      value: helpers.serviceLocationTypes.IN_PERSON,
    },
    {
      text: trans("service_location_2"),
      value: helpers.serviceLocationTypes.PHONE,
    },
    {
      text: trans("service_location_3"),
      value: helpers.serviceLocationTypes.GOOGLE_MEET,
    },
    {
      text: trans("service_location_5"),
      value: helpers.serviceLocationTypes.ZOOM,
    },
    {
      text: trans("service_location_6"),
      value: helpers.serviceLocationTypes.TEAMS,
    },
    {
      text: trans("service_location_7"),
      value: helpers.serviceLocationTypes.SKYPE,
    },
    {
      text: trans("service_location_8"),
      value: helpers.serviceLocationTypes.WHATSAPP,
    },
    {
      text: trans("service_location_4"),
      value: helpers.serviceLocationTypes.OTHER,
    },
  ];
}

export function paymentMethodsForSelect(includeSubscriptionMethod) {
  const data = [
    {
      text: trans("payment_method_cash"),
      value: helpers.paymentMethods.CASH,
    },
    {
      text: trans("payment_method_stripe"),
      value: helpers.paymentMethods.STRIPE,
    },
    {
      text: trans("payment_method_transfer"),
      value: helpers.paymentMethods.TRANSFER,
    },
    {
      text: trans("payment_method_card"),
      value: helpers.paymentMethods.CARD,
    },
  ];

  if (includeSubscriptionMethod) {
    data.push({
      text: trans("payment_method_subscription"),
      value: helpers.paymentMethods.SUBSCRIPTION,
    });
  }

  data.push({
    text: trans("payment_method_other"),
    value: helpers.paymentMethods.OTHER,
  });

  return data;
}

export function errorResponseIsTypeOfCode(error, code) {
  return (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.code &&
    error.response.data.code === code
  );
}

export function parseRruleString(rrule) {
  rrule = rrule.replace(/\n/g, ";").replace("RRULE:", "");

  let result = {};
  const rruleParts = rrule.split(";");

  for (let i = 0; i < rruleParts.length; i++) {
    let part = rruleParts[i].split("=");
    let key = part[0];
    let value = part[1];

    if (key === "UNTIL" || key === "DTSTART") {
      const momentDate = moment.utc(value, "YYYYMMDDTHHmmssZ");
      value = momentDate.toDate();
    }

    if (key === "BYDAY") {
      value = value.split(",");
    }

    result[key] = value;
  }

  return result;
}

export function getUnique(array, key) {
  return [
    ...new Map(
      array
        .filter((item) => item && item[key] !== undefined)
        .map((item) => [item[key], item]),
    ).values(),
  ];
}

export function getEmployeeForId(employeeId) {
  const employees = store.getters["employee/getAllEmployees"];
  return employees.find(
    (employee) => parseInt(employee.id) === parseInt(employeeId),
  );
}

export function getCategoryForId(categoryId) {
  const categories = store.getters["category/categoryWithServices"];
  const category = categories.find((category) => categoryId === category.id);

  if (category) {
    return {
      id: category.id,
      name: category.name,
      status: category.status,
      order_position: category.order_position,
    };
  }

  return null;
}

export function getServiceAndItsTypeForId(
  serviceId,
  serviceTypeId = null,
  includeCategory = false,
) {
  const services = store.getters["category/getServices"];
  const service = services.find((service) => serviceId === service.id);

  if (service) {
    if (serviceTypeId && service.types) {
      service.selectedType = service.types.find(
        (serviceType) => serviceTypeId === serviceType.id,
      );
    }

    if (includeCategory) {
      service.category = getCategoryForId(service.category_id);
    }

    return service;
  }

  return null;
}

export function handleMeetUsClick() {
  pushEvent("openMeetUs");

  if (getItem("locale", false) === "pl") {
    window.open("https://support.calendesk.net/", "_blank");
  } else {
    window.open("https://support-en.calendesk.net/", "_blank");
  }
}

export function handleOpenChatClick() {
  if (typeof window.$chatwoot.toggle === "function") {
    window.$chatwoot.toggle();
  } else {
    log.error("$chatwoot not installed!");
  }
}

export function addressIsEmpty(defaultAddress) {
  return (
    !defaultAddress ||
    (defaultAddress &&
      defaultAddress.name === null &&
      defaultAddress.street === null &&
      defaultAddress.postal_code === null &&
      defaultAddress.city === null &&
      defaultAddress.country_iso_code === null &&
      defaultAddress.vat_iso_prefix === null &&
      defaultAddress.tax_number === null)
  );
}

export function canIssueInvoiceForUser(user) {
  const defaultAddress = user.default_address;

  return (
    defaultAddress &&
    (defaultAddress.name || user.name || user.surname || user.email) &&
    defaultAddress.street &&
    defaultAddress.postal_code &&
    defaultAddress.city &&
    defaultAddress.country_iso_code
  );
}

export function getStripePriceName(price) {
  const amount = money(price.unit_amount, price.currency);

  if (price.recurring_interval) {
    return `${amount} (${trans("recurrent_each").toLowerCase()} ${plural(
      price.interval_count,
      price.recurring_interval,
      true,
    )})`;
  }

  return amount;
}

export function generateRandomString(length) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result + Date.now().toString();
}

export function getConvertedEventDates(
  startsAt,
  endsAt,
  allDay,
  skipSubtractDaysInAllDayEvents = false,
) {
  const platformTz = platformTimeZone();
  const userTz = userTimeZone();

  // Parse the start and end times in UTC
  let startDate = moment.utc(startsAt);
  let endDate = moment.utc(endsAt);

  // Choose the final timezone based on whether it's an all-day event
  const finalTz = allDay ? platformTz : userTz;

  // Convert to the appropriate timezone
  startDate = startDate.clone().tz(finalTz);
  endDate = endDate.clone().tz(finalTz);

  if (allDay && !skipSubtractDaysInAllDayEvents) {
    // Subtract one day from the end date for all-day events
    endDate = endDate.subtract(1, "day");
  }

  return {
    finalTz,
    startDate,
    endDate,
    allDay,
    formattedStartDate: startDate.format(helpers.dateFormat),
    formattedEndDate: endDate.format(helpers.dateFormat),
    formattedStartTime: !allDay ? startDate.format(helpers.timeFormat) : null,
    formattedEndTime: !allDay ? endDate.format(helpers.timeFormat) : null,
  };
}

export default helpers;

import api from "@/lib/calendesk-js-library/api/api";

const getDefaultState = () => ({
  defaultPaymentMethod: null,
  invoiceData: null,
  subscriptionToPay: null,
  reload: null,
  isReverseChargeApplied: false,
});

const state = getDefaultState();

const getters = {
  getDefaultPaymentMethod: (state) => state.defaultPaymentMethod,
  getInvoiceData: (state) => state.invoiceData,
  getSubscriptionToPay: (state) => state.subscriptionToPay,
  getReload: (state) => state.reload,
  getIsReverseChargeApplied: (state) => state.isReverseChargeApplied,
};

const actions = {
  fetchDefaultPaymentMethod({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getDefaultPaymentMethod()
        .then(({ data }) => {
          commit("SET_DEFAULT_PAYMENT_METHOD", data);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deletePaymentMethods({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .deletePaymentMethods()
        .then(({ data }) => {
          commit("SET_DEFAULT_PAYMENT_METHOD", null);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDefaultPaymentMethodInvokeToken(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getDefaultPaymentMethodInvokeToken(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchInvoiceData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getTenantsInvoiceData()
        .then(({ data }) => {
          commit("SET_INVOICE_DATA", data);
          commit(
            "SET_IS_REVERSE_CHARGE_APPLIED",
            !!(data && data.reverse_charge)
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateInvoiceData({ commit }, data) {
    return new Promise((resolve, reject) => {
      api
        .updateTenantsInvoiceData(data)
        .then(({ data }) => {
          commit("SET_INVOICE_DATA", data);
          commit(
            "SET_IS_REVERSE_CHARGE_APPLIED",
            !!(data && data.reverse_charge)
          );
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setupDefaultPaymentMethod({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      api
        .setupDefaultPaymentMethod(data)
        .then(({ data }) => {
          dispatch("fetchDefaultPaymentMethod").finally(() => {
            resolve(data);
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  subscribePlan(context, data) {
    return new Promise((resolve, reject) => {
      api
        .subscribePlan(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  cancelSubscription() {
    return new Promise((resolve, reject) => {
      api
        .cancelSubscription()
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  retryPastDueSubscriptionPayment(context, data) {
    return new Promise((resolve, reject) => {
      api
        .retryPastDueSubscriptionPayment(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPastDueSubscriptionInvoice(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getPastDueSubscriptionInvoice(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchPaymentsBookingsUser(context, data) {
    return new Promise((resolve, reject) => {
      api
        .getPaymentsBookings(data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  setSubscriptionToPay({ commit }, subscriptionToPay) {
    commit("SET_SUBSCRIPTION_TO_PAY", subscriptionToPay);
  },
  setIsReverseChargeApplied({ commit }, isReverseChargeApplied) {
    commit("SET_IS_REVERSE_CHARGE_APPLIED", isReverseChargeApplied);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_DEFAULT_PAYMENT_METHOD(state, data) {
    state.defaultPaymentMethod = data;
  },
  SET_IS_REVERSE_CHARGE_APPLIED(state, data) {
    state.isReverseChargeApplied = data;
  },
  SET_INVOICE_DATA(state, data) {
    state.invoiceData = data;
  },
  SET_SUBSCRIPTION_TO_PAY(state, data) {
    state.subscriptionToPay = data;
  },
  SET_RELOAD(state) {
    state.reload = Math.floor(Math.random() * 100000000);
  },
  SET_SUBSCRIPTION(state, data) {
    state.subscription = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

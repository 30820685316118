<template>
  <v-dialog
    v-model="showDialog"
    :width="900"
    content-class="white"
    @click:outside="closeDialog"
  >
    <v-container class="white fill-height">
      <v-row justify="center" no-gutters>
        <v-col cols="12" md="6" class="d-flex align-center">
          <v-container>
            <v-row>
              <v-col>
                <span class="text-h4">{{ $trans("verify_code_title") }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-h6">
                  {{ $trans("verify_code_description") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-otp-input
                  v-model="code"
                  length="6"
                  class="mt-4"
                  input-classes="v-text-field text-center"
                  :disabled="isVerifyingCode"
                  @finish="verifyCode"
                />
              </v-col>
            </v-row>
            <v-row v-if="canNotGenerateCodeError">
              <v-col>
                <calendesk-warning-message>
                  {{ $trans("verify_code_too_many_requests") }}
                </calendesk-warning-message>
              </v-col>
            </v-row>
            <v-row class="mt-6">
              <v-col class="d-flex align-center flex-wrap">
                <v-btn
                  outlined
                  x-large
                  :disabled="!code || isVerifyingCode"
                  @click="verifyCode"
                >
                  {{ $trans("verify_title") }}
                </v-btn>
                <v-btn
                  v-if="canNotGenerateCodeError"
                  class="ml-2"
                  outlined
                  x-large
                  :disabled="isSendingCode"
                  @click="sendVerificationCode"
                >
                  {{ $trans("send_again_button_title") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col class="d-none d-md-flex align-center justify-center" cols="6">
          <v-img
            :src="require('@/lib/calendesk-js-library/assets/verification.png')"
            class="ma-2"
            contain
            max-height="600"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<script>
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";
import api from "@/lib/calendesk-js-library/api/api";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import CalendeskWarningMessage from "@/lib/calendesk-js-library/components/CalendeskWarningMessage.vue";

export default {
  name: "EmailExtraVerification",
  components: { CalendeskWarningMessage },
  data() {
    return {
      showDialog: false,
      emailToVerify: null,
      code: null,
      resolveCallback: null,
      rejectCallback: null,
      isSendingCode: false,
      isVerifyingCode: false,
      canNotGenerateCodeError: false,
    };
  },
  created() {
    EventBus.$on(
      "EMAIL_EXTRA_VERIFICATION",
      this.handleEmailExtraVerificationEvent,
    );
  },
  beforeDestroy() {
    EventBus.$off(
      "EMAIL_EXTRA_VERIFICATION",
      this.handleEmailExtraVerificationEvent,
    );
  },
  methods: {
    verifyCode() {
      this.isVerifyingCode = true;

      api
        .verifyCode({
          code: this.code,
          receiver: this.emailToVerify,
        })
        .then(() => {
          this.resolveCallback(this.code);
          this.closeDialog();
        })
        .catch((error) => {
          errorNotification("verify_code_incorrect_error", error, false);
        })
        .finally(() => {
          this.isVerifyingCode = false;
        });
    },
    closeDialog() {
      this.showDialog = false;
      this.rejectCallback();
      this.clearData();
    },
    handleEmailExtraVerificationEvent(resolve, reject, emailToVerify) {
      this.showDialog = true;
      this.emailToVerify = emailToVerify;
      this.resolveCallback = resolve;
      this.rejectCallback = reject;
      this.sendVerificationCode();
    },
    sendVerificationCode() {
      this.isSendingCode = true;
      api
        .verifyEmail({
          email: this.emailToVerify,
        })
        .then(() => {
          this.canNotGenerateCodeError = false;
        })
        .catch(() => {
          this.canNotGenerateCodeError = true;
        })
        .finally(() => {
          this.isSendingCode = false;
        });
    },
    clearData() {
      this.emailToVerify = null;
      this.code = null;
      this.resolveCallback = null;
      this.rejectCallback = null;
      this.canNotGenerateCodeError = false;
      this.isSendingCode = false;
      this.isVerifyingCode = false;
    },
  },
};
</script>

<template>
  <v-app>
    <div v-if="getCdAdminSessionIssuer" style="min-height: 50px">
      <calendesk-information-message
        color="orange"
        additional-class="white--text full-width d-flex align-center justify-center mb-0"
        icon-color="white"
        icon="$alert"
      >
        Logged in as super admin (ID: {{ getCdAdminSessionIssuer }})
        <v-btn
          small
          color="white"
          outlined
          class="mx-2"
          :loading="isClosingAdminSession"
          :disabled="isClosingAdminSession"
          @click="closeAdminSession"
        >
          <v-icon left>$logout</v-icon>
          Close session
        </v-btn>
      </calendesk-information-message>
    </div>

    <router-view />

    <div v-if="isPreloading" class="preloader-wrapper">
      <div class="preloader">
        <div class="">
          <img height="20" :src="require(`@/assets/logo.png`)" />
          <v-progress-linear :value="loadingValue" height="2" color="primary" />
        </div>
      </div>
    </div>

    <v-dialog
      v-if="showEmployeeLimitDialog"
      v-model="showEmployeeLimitDialog"
      :width="900"
      content-class="white"
    >
      <employee-limit-dialog @close="showEmployeeLimitDialog = false" />
    </v-dialog>

    <v-dialog
      v-if="showRequiredCapabilityDialog"
      v-model="showRequiredCapabilityDialog"
      :width="900"
      content-class="white"
    >
      <required-capability-dialog
        @close="showRequiredCapabilityDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="showRequiredPermissionDialog"
      v-model="showRequiredPermissionDialog"
      :width="900"
      content-class="white"
    >
      <required-permission-dialog
        @close="showRequiredPermissionDialog = false"
      />
    </v-dialog>

    <v-dialog
      v-if="showNotificationUsageNotAvailableDialog"
      v-model="showNotificationUsageNotAvailableDialog"
      :width="900"
      content-class="white"
    >
      <notification-usage-not-available-dialog
        @close="showNotificationUsageNotAvailableDialog = false"
      />
    </v-dialog>

    <email-extra-verification />

    <v-dialog
      v-if="showGlobalErrorDialog"
      v-model="showGlobalErrorDialog"
      fullscreen
      content-class="white"
      persistent
    >
      <global-error @close="showGlobalErrorDialog = false" />
    </v-dialog>
  </v-app>
</template>

<script>
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus.js";
import RequiredCapabilityDialog from "@/lib/calendesk-js-library/components/dialogs/components/RequiredCapabilityDialog";
import RequiredPermissionDialog from "@/lib/calendesk-js-library/components/dialogs/components/RequiredPermissionDialog";
import GlobalError from "@/lib/calendesk-js-library/components/GlobalError";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import NotificationUsageNotAvailableDialog from "@/lib/calendesk-js-library/components/dialogs/components/NotificationUsageNotAvailableDialog";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import EmployeeLimitDialog from "@/lib/calendesk-js-library/components/dialogs/components/EmployeeLimitDialog.vue";
import { mapActions, mapGetters } from "vuex";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import EmailExtraVerification from "@/components/EmailExtraVerification.vue";

export default {
  name: "App",
  components: {
    EmailExtraVerification,
    CalendeskInformationMessage,
    EmployeeLimitDialog,
    NotificationUsageNotAvailableDialog,
    GlobalError,
    RequiredPermissionDialog,
    RequiredCapabilityDialog,
  },
  mixins: [sharedActions],
  data() {
    return {
      showRequiredCapabilityDialog: false,
      showEmployeeLimitDialog: false,
      showRequiredPermissionDialog: false,
      showGlobalErrorDialog: false,
      showNotificationUsageNotAvailableDialog: false,
      loadingValue: 10,
      interval: 0,
      isClosingAdminSession: false,
    };
  },
  computed: {
    ...mapGetters({
      cdAdminSessionIssuer: "auth/getCdAdminSessionIssuer",
    }),
    getCdAdminSessionIssuer() {
      if (this.cdAdminSessionIssuer) {
        const parts = this.cdAdminSessionIssuer.split("_");
        return parts.length > 1 ? parts[1] : this.cdAdminSessionIssuer;
      }

      return null;
    },
  },
  watch: {
    loadingValue(val) {
      if (val >= 100) {
        clearInterval(this.interval);
      }
    },
  },
  mounted() {
    this.startLoadingSimulation();
  },
  created() {
    pushEvent("openApp");

    EventBus.$on("EMPLOYEE_LIMIT", this.handleEmployeeLimitEvent);
    EventBus.$on("REQUIRED_CAPABILITY", this.handleRequiredCapabilityEvent);
    EventBus.$on("REQUIRED_PERMISSION", this.handleRequiredPermissionEvent);
    EventBus.$on("GLOBAL_ERROR", this.handleGlobalErrorEvent);
    EventBus.$on(
      "NOTIFICATION_USAGE_NOT_AVAILABLE",
      this.handleNotificationUsageNotAvailableEvent,
    );
  },
  beforeDestroy() {
    EventBus.$off("EMPLOYEE_LIMIT", this.handleEmployeeLimitEvent);
    EventBus.$off("REQUIRED_CAPABILITY", this.handleRequiredCapabilityEvent);
    EventBus.$off("REQUIRED_PERMISSION", this.handleRequiredPermissionEvent);
    EventBus.$off("GLOBAL_ERROR", this.handleGlobalErrorEvent);
    EventBus.$off(
      "NOTIFICATION_USAGE_NOT_AVAILABLE",
      this.handleNotificationUsageNotAvailableEvent,
    );

    clearInterval(this.interval);
  },
  methods: {
    ...mapActions({
      setIsCdAdminSessionIssuer: "auth/setIsCdAdminSessionIssuer",
    }),
    startLoadingSimulation() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.loadingValue += Math.random() * (15 - 5) + 5;
      }, 500);
    },
    handleEmployeeLimitEvent() {
      this.showEmployeeLimitDialog = true;
    },
    handleRequiredCapabilityEvent() {
      this.showRequiredCapabilityDialog = true;
    },
    handleRequiredPermissionEvent() {
      this.showRequiredPermissionDialog = true;
    },
    handleGlobalErrorEvent() {
      this.showGlobalErrorDialog = true;
    },
    handleNotificationUsageNotAvailableEvent() {
      this.showNotificationUsageNotAvailableDialog = true;
    },
    closeAdminSession() {
      this.isClosingAdminSession = true;
      this.setIsCdAdminSessionIssuer(null);
      this.$store.dispatch("auth/logoutUserAndDestroySession");
    },
  },
};
</script>

<style lang="scss">
.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--v-primary-base);
  background-color: #ffffff;
}

.preloader-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 9999999999;
}
</style>

import Vue from "vue";

/* filters */
import duration from "../../lib/calendesk-js-library/filters/duration";
import money from "../../lib/calendesk-js-library/filters/money";
import date from "./date";
import phoneNumber from "./phoneNumber";
import truncate from "../../lib/calendesk-js-library/filters/truncate";
import lowercase from "../../lib/calendesk-js-library/filters/lowercase";
import fromNow from "@/calendesk/filters/fromNow";
import tenantVariable from "@/calendesk/filters/tenantVariable";
import plural from "@/lib/calendesk-js-library/filters/plural";
import dateTime from "@/lib/calendesk-js-library/filters/dateTime";

Vue.filter("money", money);
Vue.filter("duration", duration);
Vue.filter("date", date);
Vue.filter("phoneNumber", phoneNumber);
Vue.filter("truncate", truncate);
Vue.filter("lowercase", lowercase);
Vue.filter("fromNow", fromNow);
Vue.filter("tenantVariable", tenantVariable);
Vue.filter("plural", plural);
Vue.filter("dateTime", dateTime);
